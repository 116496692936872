import React from 'react';
import './Product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Product = (props) => {
  return (
    <div className="p-wrapper">
      <div
        className={`logo  ${props.compare ? 'compare' : ''}`}
        onClick={(event) => props.compareProducts(event, props.link)}
        data-uk-tooltip={`title:${
          props.compare
            ? 'Remove item from compare list'
            : 'Add item to compare list'
        }`}
      >
        <img alt={props.name} src={require(`../assets/images/${props.logo}`)} />
        <FontAwesomeIcon icon={faStar} />
      </div>
      <div className="link-wrapper">
        <div className="img">
          <img alt={props.description} src={props.imgSrc} />
        </div>

        <div className="priceBtn">
          <span className="price">{props.price} </span>
        </div>

        <div className="description">{props.description}</div>
        <a
          target="_blank"
          rel="noreferrer"
          className="clickAreaLink"
          data-store="crc"
          href={props.link}
        ></a>
      </div>
    </div>
  );
};

export default Product;
