import { createSlice } from '@reduxjs/toolkit';
import stores from '../data/stores';

const storeList = createSlice({
    name: 'storeList',
    initialState: stores,
    reducers: {
        updateStores: (state, action) => {
            const clickedStore = action.payload.storeName;

            state.map((store) => {
                if(store.name === clickedStore) {
                    store.checked = !store.checked;
                }
                return store;
            });

        }
    }
});

export const storeActions = storeList.actions;

export default storeList.reducer;