import React from 'react';

const SearchSection = ({ children }) => {
  return (
    <React.Fragment>
      <div className="searchSection">
        <div className="container">{children}</div>
      </div>
    </React.Fragment>
  );
};

export default SearchSection;
