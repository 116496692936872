import React from 'react';
import Product from './Product';
const TabContent = (props) => {
  let products = [];
  if (props.result.products) {
    products = props.result.products.map((product, i) => {
      return (
        <Product
          name={product.name}
          compare={product.compare}
          compareProducts={props.compareProducts}
          key={i + '' + props.name}
          link={product.link}
          price={product.price}
          imgSrc={product.imgSrc}
          description={product.description}
          logo={product.logo}
        />
      );
    });
  } else {
    products = <li id="none-portal">other</li>;
  }
  return (
    <li className={`products-wrapper ${props.result.store}`}>{products}</li>
  );
};

export default TabContent;
