import React from 'react';
import './StoreSelect.css';

const StoreSelect = (props) => {
  return (
    <div className={props.className}>
      <input
        data-testid={props.id}
        defaultChecked={props.checked}
        type="checkbox"
        id={props.id}
        value={props.value}
      />
      <label
        data-testid={`${props.id}-label`}
        className={props.className}
        htmlFor={props.id}
        onClick={() => props.setStoreList(props.id)}
      ></label>
    </div>
  );
};
export default StoreSelect;
