import React from 'react';
import StoreSelect from './StoreSelect';
import { useDispatch } from 'react-redux';
import { storeActions } from '../store/storeList';
import { useSelector } from 'react-redux';

const StoreSelectList = () => {
  const dispatch = useDispatch();

  const storeList = useSelector((state) => state.storeList);

  const setStoreList = (storeName) => {
    dispatch(storeActions.updateStores({ storeName }));
  };

  const storeSelectList = storeList.map((store) => {
    return (
      <StoreSelect
        checked={store.checked ? 'checked' : ''}
        className={store.name}
        key={store.name}
        id={store.name}
        value={store.name}
        setStoreList={setStoreList}
      />
    );
  });

  return (
    <div id="stores">
      {storeSelectList}
      <div className="bikebug">
        <input checked readOnly type="checkbox" value="bikebug" />
        <label className="bikebug" htmlFor="bikebug"></label>
      </div>
    </div>
  );
};

export default StoreSelectList;
