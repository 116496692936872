import React from 'react';
import './SearchForm.css';

const SearchForm = (props) => {
  return (
    <div className="compare-search uk-margin">
      <form className="" action="#" onSubmit={props.onSubmit}>
        <input
          data-cy="part-search-form"
          data-uk-tooltip="title:Specific searches work best, EG: Dura ace pedals"
          className="uk-width-3-4"
          type="text"
          value={props.searchText}
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'New multi store seach')}
          placeholder="Multi Store Search"
          onChange={props.onChange}
        />
        <button
          className="uk-width-1-4 button search-button expand"
          type="submit"
          value="Compare Prices"
        >
          <i className="fa fa-search"></i>
          <span className="searchBtnText">
            {' '}
            Search
            <span className="stores-count">
              {' '}
              {props.checkedStoreCount + 1}
            </span>{' '}
            Stores
          </span>
        </button>
      </form>
    </div>
  );
};
export default SearchForm;
