import React from 'react';
import bvLogo from '../assets/images/bv-logo.png';
import './Header.css';
const Header = () => {
  return (
    <header className="App-header">
      <div className="top-bar" id="top-bar-menu">
        <ul className="menu">
          <li>
            <img alt="bikevelo" src={bvLogo} />
            <span className="sub-title">The bicycle store search engine</span>
            <span className="tagline">Save Time | Save Money</span>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
