import React from 'react';
import TabButton from './TabButton';
import TabContent from './TabContent';
import './resultTabs.css';

const ResultTabs = ({
  resultReady,
  searchResult,
  compareProducts,
  searchQuery,
}) => {
  if (resultReady) {
    const tabButton = searchResult.map((result) => {
      const compareCount =
        result.name === 'Compare List' ? result.products.length : '';
      return result.hasResult ? (
        <TabButton
          compareCount={compareCount}
          compareProducts={compareProducts}
          key={result.name}
          resultName={result.name}
          logo={result.logo}
        />
      ) : (
        false
      );
    });

    const tabContent = searchResult.map((result) => {
      return result.hasResult ? (
        <TabContent
          compareProducts={compareProducts}
          name={result.name}
          key={result.name}
          result={result}
        />
      ) : (
        false
      );
    });
    const bikebug = tabContent.length ? (
      <ul className="bikebugTab">
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.bikebug.com/?query=${searchQuery}`}
          >
            BikeBug
          </a>
        </li>
      </ul>
    ) : (
      ''
    );

    return (
      <div data-uk-grid id="results">
        <div className="uk-width-auto tab-link-wrapper">
          <div className="tab-btn-inner" data-uk-sticky="bottom: true">
            <ul
              className="uk-tab-left"
              data-uk-tab="connect: #component-tab-left; animation: uk-animation-fade"
            >
              {tabButton}
            </ul>
            {bikebug}
          </div>
        </div>

        <div className="uk-width-expand">
          <ul id="component-tab-left" className="uk-switcher">
            {tabContent}
          </ul>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default ResultTabs;
