const stores = [
    {
        name:'bicyclestore',
        friendly:'Bicycle Store',
        checked:true
    },
    {
        name:'_99bikes',
        friendly:'99 Bikes',
        checked:true
    },
    {
        name:'pushys',
        friendly:'Pushys',
        checked:true
    },
    {
        name:'bikeexchange',
        friendly:'Bike Exchange',
        checked:true
    },
    {
        name:'crc',
        friendly:'Chain Reaction Cycles',
        checked:true
    },
    {
        name:'wiggle',
        friendly:'Wiggle',
        checked:true
    },
    {
        name:'pbk',
        friendly:'Pro Bike Kit',
        checked:true
    },
    {
        name:'ebay',
        friendly:'Ebay',
        checked:true
    }
]

export default stores;