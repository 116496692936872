const quotes = [];
quotes[0] =
  'Life is like riding a bicycle. To keep your balance you must keep moving.';
quotes[1] =
  'Life is like an eleven speed bicycle. Most of us have gears we never use.';
quotes[2] =
  'The slow cycling record is held by Tsugunobu Mitsuishi of Japan. He remained stationary on a bicycle for 5 hours, 25 minutes in 1965.';
quotes[3] =
  'A bicycle can continue upright with no passenger at a speed of 8 miles per hour or faster.';
quotes[4] =
  'You can’t buy happiness but you can buy a bike and that’s pretty close.';
quotes[5] =
  'My biggest fear is that when I die my wife will sell my bicycles for what I told her they cost.';
quotes[6] =
  'The bicycle is just as good company as most husbands and, when it gets old and shabby, a woman can dispose of it and get a new one without shocking the entire community.';

export default quotes;
