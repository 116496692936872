import React, { useEffect, useState } from 'react';
import image from './ajax.gif';
import quotes from '../data/quotes';

const SearchModal = ({ isSearching }) => {
  const [randomQuote, setRandomQuote] = useState(1);

  useEffect(() => {
    setRandomQuote(Math.floor(Math.random() * 6 + 1));
    if (isSearching) {
      window.UIkit.modal('#search-modal').show();
    } else {
      window.UIkit.modal('#search-modal').hide();
    }
  }, [isSearching]);
  return (
    <div id="search-modal" data-uk-modal>
      <div className="uk-modal-dialog uk-modal-body">
        <h2 className="uk-modal-title">Searching...</h2>
        <img alt="spinner" src={image} />
        <p> {quotes[randomQuote]}</p>
      </div>
    </div>
  );
};

export default SearchModal;
