const htmlRationalization = (result) => {
  let el = null;
  let resultHTMLArr = [];
  let resultObjArray = [];
  let store = '';
  if (result.store === 'crc') {
    store = result.store;
    el = document.createElement('div');
    el.innerHTML = result.html;
    resultHTMLArr = el.querySelectorAll(result.innerTarget);
    resultObjArray = [];
    resultHTMLArr.forEach((result) => {
      const theResult = {
        link:
          'https://www.chainreactioncycles.com' +
          result.querySelectorAll('a')[0].getAttribute('href'),
        imgSrc: result
          .querySelectorAll('[class^=product_image] img')[0]
          .getAttribute('src'),
        description: result.querySelectorAll('.description h2')[0].textContent,
        price: result.querySelectorAll('.fromamt')[0].textContent,
        logo: 'crc-logo.jpg',
        compare: false,
        store: store,
      };
      resultObjArray.push(theResult);
    });
    result.products = resultObjArray;
    result.logo = 'crc-logo.jpg';
    result.html = null;
  }

  if (result.store === 'wiggle') {
    store = result.store;
    el = document.createElement('div');
    el.innerHTML = result.html;
    resultHTMLArr = el.querySelectorAll(result.innerTarget);
    resultObjArray = [];
    resultHTMLArr.forEach((result) => {
      const srcArr = result
        .querySelectorAll('.bem-product-thumb__image-link--grid noscript')[0]
        .textContent.split('"');
      const theResult = {
        link: result
          .querySelectorAll('.bem-product-thumb__image-link--grid')[0]
          .getAttribute('href'),
        imgSrc: srcArr[1],
        description: result.querySelectorAll(
          '.bem-product-thumb__name--grid'
        )[0].textContent,
        price: result.querySelectorAll('.bem-product-price__unit--grid')[0]
          .textContent,
        logo: 'wiggle-logo.jpg',
        compare: false,
        store: store,
      };
      resultObjArray.push(theResult);
    });
    result.products = resultObjArray;
    result.logo = 'wiggle-logo.jpg';
    result.html = null;
  }

  if (result.store === 'pbk') {
    store = result.store;
    el = document.createElement('div');
    el.innerHTML = result.html;
    resultHTMLArr = el.querySelectorAll(result.innerTarget);
    resultObjArray = [];
    resultHTMLArr.forEach((result) => {
      let price = '';
      if (result.querySelectorAll('.productBlock_fromValue').length >= 1) {
        price =
          'From ' +
          result.querySelectorAll('.productBlock_fromValue')[0].textContent;
      }
      if (result.querySelectorAll('.productBlock_priceValue').length >= 1) {
        price = result.querySelectorAll('.productBlock_priceValue')[0]
          .textContent;
      }

      const theResult = {
        link:
          'https://www.probikekit.com.au' +
          result.querySelectorAll('a')[0].getAttribute('href'),
        imgSrc: result
          .querySelectorAll('.productBlock_image')[0]
          .getAttribute('src'),
        description: result.querySelectorAll('.productBlock_productName')[0]
          .textContent,
        price: price,
        logo: 'pbk-logo.jpg',
        compare: false,
        store: store,
      };
      resultObjArray.push(theResult);
    });
    result.logo = 'pbk-logo.jpg';

    result.products = resultObjArray;
    result.html = null;
  }

  if (result.store === 'pushys') {
    store = result.store;
    el = document.createElement('div');
    el.innerHTML = result.html;
    resultHTMLArr = el.querySelectorAll(result.innerTarget);
    resultObjArray = [];
    resultHTMLArr.forEach((result) => {
      const theResult = {
        link: result.querySelectorAll('a')[0].getAttribute('href'),
        imgSrc: result
          .querySelectorAll('.product-image-photo')[0]
          .getAttribute('data-src'),
        description:
          result.querySelectorAll('.product-item-name')[0].textContent,
        price: result.querySelectorAll('.price')[0].textContent,
        logo: 'pushys-logo.png',
        compare: false,
        store: store,
      };
      resultObjArray.push(theResult);
    });
    result.products = resultObjArray;
    result.logo = 'pushys-logo.png';
    result.html = null;
  }

  if (result.store === '_99bikes') {
    store = result.store;
    el = document.createElement('div');
    el.innerHTML = result.html;
    resultHTMLArr = el.querySelectorAll(result.innerTarget);
    resultObjArray = [];
    resultHTMLArr.forEach((result) => {
      const theResult = {
        link: result
          .querySelectorAll('.product-item-photo')[0]
          .getAttribute('href'),
        imgSrc: result
          .querySelectorAll('.product-image-photo')[0]
          .getAttribute('src'),
        description:
          result.querySelectorAll('.product-item-name')[0].textContent,
        price: result.querySelectorAll('[id^="product-price"] .price')[0]
          .textContent,
        logo: '99bikes-logo.png',
        compare: false,
        store: store,
      };
      resultObjArray.push(theResult);
    });
    result.products = resultObjArray;
    result.logo = '99bikes-logo.png';
    result.html = null;
  }

  if (result.store === 'bikeexchange') {
    store = result.store;
    el = document.createElement('div');
    el.innerHTML = result.html;
    resultHTMLArr = el.querySelectorAll(result.innerTarget);
    resultObjArray = [];
    resultHTMLArr.forEach((result) => {
      const priceText =
        result.querySelectorAll('.Price-regular').length >= 1
          ? result.querySelectorAll('.Price-regular')[0].textContent
          : result.querySelectorAll('.Price-sale')[0].textContent;
      const theResult = {
        link:
          'https://www.bikeexchange.com.au' +
          result.querySelectorAll('.t-advertTileLink')[0].getAttribute('href'),
        imgSrc: result
          .querySelectorAll('.AdvertTile-imageBox')[0]
          .style.backgroundImage.slice(4, -1)
          .replace(/"/g, ''),
        description: result.querySelectorAll('.AdvertTile-content')[0]
          .textContent,
        price: priceText,
        logo: 'bikeexchange-logo.png',
        compare: false,
        store: 'Bike Exchange',
      };
      resultObjArray.push(theResult);
    });
    result.products = resultObjArray;
    result.logo = 'bikeexchange-logo.png';
    result.html = null;
  }

  if (result.store === 'bicyclestore') {
    store = result.store;
    el = document.createElement('div');
    el.innerHTML = result.html;
    resultHTMLArr = el.querySelectorAll(result.innerTarget);
    resultObjArray = [];
    resultHTMLArr.forEach((result) => {
      const theResult = {
        link: result.querySelectorAll('a')[0].getAttribute('href'),
        imgSrc: result
          .querySelectorAll('.product-image img')[0]
          .getAttribute('src'),
        description: result.querySelectorAll('.product-name')[0].textContent,
        price: result.querySelectorAll('.regular-price .price')[0].textContent,
        logo: 'the-bicycle-store-logo.jpg',
        compare: false,
        store: 'Bicycle Store',
      };
      resultObjArray.push(theResult);
    });
    result.products = resultObjArray;
    result.logo = 'the-bicycle-store-logo.jpg';
    result.html = null;
  }

  if (result.store === 'tbe') {
    store = result.store;
    el = document.createElement('div');
    el.innerHTML = result.html;
    resultHTMLArr = el.querySelectorAll(result.innerTarget);
    resultObjArray = [];
    resultHTMLArr.forEach((result) => {
      const theResult = {
        link:
          'https://www.tbe.com.au' +
          result.querySelectorAll('a')[0].getAttribute('href'),
        imgSrc: result
          .querySelectorAll('.product-image img')[0]
          .getAttribute('src'),
        description: result.querySelectorAll('.product-name')[0].textContent,
        price: result.querySelectorAll('.price')[0].textContent,
        logo: 'tbe-logo.jpg',
        compare: false,
        store: store,
      };
      resultObjArray.push(theResult);
    });
    result.products = resultObjArray;
    result.logo = 'tbe-logo.jpg';
    result.html = null;
  }

  if (result.store === 'ebay') {
    store = result.store;
    result.products.forEach((product) => {
      product.logo = 'ebay-logo.png';
      product.compare = false;
      product.store = store;
    });
    resultObjArray = result.products;
    result.logo = 'ebay-logo.png';
  }

  return result;
};

export default htmlRationalization;
