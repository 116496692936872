import React from 'react';

const SearchHistoryItem = (props) => {
  return (
    <button
      onClick={props.historySearchClick}
      className="button recent-search"
      data-search-term={props.searchTerm}
    >
      <i className="fa fa-history"></i> {props.searchTerm}
    </button>
  );
};
export default SearchHistoryItem;
