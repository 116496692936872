import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';

import './App.css';
import SearchSection from './SearchSection';
import axios from 'axios';
import ResultTabs from '../components/ResultTabs';
import htmlRationalization from '../assets/js/htmlRationalization';
import SearchModal from '../components/SearchModal';
import Header from '../components/Header';

import StoreSelectList from '../components/StoreSelectList';
import SearchHistoryList from '../components/SearchHistoryList';
import SearchForm from '../components/SearchForm';

function App() {
  const results = useRef();
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [resultReady, setResultReady] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  //const [compareList, setCompareList] = useState([]);

  const storeListR = useSelector((state) => state.storeList);

  const checkedStoreListR = storeListR.filter((s) => {
    return s.checked === true;
  });

  useEffect(() => {
    if (
      searchResult.length >= 1 &&
      searchResult.length === checkedStoreListR.length
    ) {
      setResultReady(true);
      setIsSearching(false);

      window.setTimeout(() => {
        results.current.scrollIntoView({ behavior: 'smooth' });
        window.UIkit.tab('.uk-tab').show(1);
      }, 500);
    }
  }, [searchResult, checkedStoreListR]);

  const triggerSearch = (searchText) => {
    setSearchResult([]);
    setResultReady(false);
    setIsSearching(true);

    storeListR.map((store) => {
      if (store.checked === true) {
        axios
          .get(
            `https://allbikestoresau.com/results.php?store=${store.name}&react=true&q=${searchText}`,
            { timeout: 10000 }
          )
          .then((result) => {
            const resultData = result.data;
            const htmlRes = htmlRationalization(resultData);
            setSearchResult((currentResults) => [...currentResults, htmlRes]);
          })
          .catch((err) => {
            const error = {
              hasResult: false,
              errorMsg: err.message,
            };
            setSearchResult((currentResults) => [...currentResults, error]);
          });
        return false;
      }
    });

    const compareTab = {
      logo: 'ebay-logo.png',
      name: 'Compare List',
      store: 'bv',
      hasResult: true,
      products: [],
    };
    //const results = [...searchResult];
    setSearchResult((currentResults) => [...currentResults, compareTab]);
  };
  const compareProducts = (event, link) => {
    // if(localStorage.getItem('compareList')) {
    //   let newCompareList = JSON.parse(localStorage.getItem('compareList'));
    // }else{
    //   let newCompareList = [];
    // }

    const newCompareList = [];

    const deepCopyObj = JSON.parse(JSON.stringify(searchResult));
    deepCopyObj.map((result) => {
      if (result.hasResult === true) {
        result.products.map((s) => {
          if (s.store !== 'bv') {
            if (s.link === link) {
              s.compare = !s.compare;
            }
            if (s.compare == true) {
              const bv = { ...s };
              bv.store = 'bv';
              newCompareList.push(bv);
            }
          }
        });
      }
    });

    // localStorage.setItem('compareList', JSON.stringify(deepCopyList));
    // localStorage.setItem('compareListCount', deepCopyList.length);

    deepCopyObj.map((result) => {
      if (result.hasResult === true) {
        if (result.store == 'bv') {
          result.products = newCompareList;
        }
      }
    });

    setSearchResult(deepCopyObj);
  };

  useEffect(() => {
    const searchResultCopy = [...searchResult];
    searchResultCopy.map((result) => {
      if (result.store === 'bv') {
        //result.products = compareList;

        result.hasResult = true;
      }
      return result;
    });
    //   setSearchResult(searchResultCopy);
  }, [searchResult]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (searchText) {
      //addSearchTermToHistory(searchText);
      triggerSearch(searchText);
    }
  };

  const searchTextHandler = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className="App">
      <Header />
      <SearchSection>
        <StoreSelectList />
        <SearchForm
          onChange={searchTextHandler}
          onSubmit={submitHandler}
          searchText={searchText}
          checkedStoreCount={checkedStoreListR.length}
        />
        <SearchHistoryList
          isSearching={isSearching}
          searchTerm={searchText}
          triggerSearch={triggerSearch}
          setSearchTerm={setSearchText}
        />
      </SearchSection>
      <span id="res" ref={results}></span>
      <ResultTabs
        compareProducts={compareProducts}
        searchResult={searchResult}
        resultReady={resultReady}
        searchQuery={searchText}
      />
      <SearchModal isSearching={isSearching} />
    </div>
  );
}

export default App;
