import React from 'react';

//const images = require.context("../assets/images", true);
const TabButton = (props) => {
  //const img = images(`./${props.logo}`).default;

  const classname = props.resultName.replace(' ', '-');
  //var compareCount = props.compareProducts.length ? props.compareProducts.length : 0;
  const listItem =
    props.resultName === 'Compare List' ? (
      <li className={'_' + classname}>
        <a href="#">
          {props.resultName}{' '}
          <span className="uk-badge">{props.compareCount}</span>
        </a>
      </li>
    ) : (
      <li className={'_' + classname}>
        <a href="#">{props.resultName}</a>
      </li>
    );

  return (
    // <li style={{backgroundImage: `url(${img})`}} className={"_"+classname}><a href="#">{props.resultName === "Compare List" ? props.resultName : " "}</a></li>
    listItem
  );
};

export default TabButton;
