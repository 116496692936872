import React, { useEffect, useState } from 'react';
import SearchHistoryItem from './SearchHistoryItem';

const SearchHistoryList = ({
  isSearching,
  searchTerm,
  setSearchTerm,
  triggerSearch,
}) => {
  const [searchHistory, setSearchHistory] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('searchHistory')) {
      const savedSearchHistory = JSON.parse(
        localStorage.getItem('searchHistory')
      );
      setSearchHistory(savedSearchHistory);
    }
  }, []);

  useEffect(() => {
    const addSearchTermToHistory = (searchText) => {
      console.log('adding search tem to history' + searchText);
      let found = false;
      for (let i = 0; i < searchHistory.length; i++) {
        if (searchHistory[i].searchTerm === searchText) {
          found = true;
          break;
        }
      }
      if (!found) {
        const newSearchTerm = { searchTerm: searchText };
        let latestSearchHistory = [...searchHistory];
        if (latestSearchHistory.length > 2) {
          latestSearchHistory = latestSearchHistory.slice(1);
        }

        latestSearchHistory.push(newSearchTerm);
        setSearchHistory(latestSearchHistory);
        localStorage.setItem(
          'searchHistory',
          JSON.stringify(latestSearchHistory)
        );
      }
    };
    if (isSearching === true) {
      addSearchTermToHistory(searchTerm);
    }
  }, [isSearching]);

  const historySearchHandler = (e) => {
    const searchTerm = e.target.getAttribute('data-search-term');
    setSearchTerm(searchTerm);
    triggerSearch(searchTerm);
  };

  const searchHistoryItems = searchHistory.map((item) => {
    return (
      <SearchHistoryItem
        searchTerm={item.searchTerm}
        historySearchClick={historySearchHandler}
        key={item.searchTerm}
      />
    );
  });

  return (
    <div className="history">
      <div className="small button-group">{searchHistoryItems}</div>
    </div>
  );
};

export default SearchHistoryList;
